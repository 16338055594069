<template>
  <div class="text-black w-full flex flex-col h-screen" v-if="dataFetched">
    <Navbar />
    <div id="desktop-content" class="mx-10 hidden md:block" v-if="data">
      <div id="hero-card" class="relative" v-if="data.hero_experience">
        <div
          class="w-full h-full absolute top-0 left-0 bg-black bg-opacity-50"
          v-if="!data.hero_experience.availability.status"
        ></div>
        <router-link
          :to="
            data.hero_experience.availability.status
              ? {
                  name: 'ExperienceDetailPage',
                  params: {
                    experience_link_id: data.hero_experience.experience_link_id,
                  },
                }
              : {}
          "
          v-if="data.hero_experience"
        >
          <img
            :src="data.hero_experience.experience.hero_card"
            class="mt-4 w-full h-auto"
          />
          <div class="text-white absolute top-10 left-10" v-if="currentuser">
            <div class="font-semibold text-8xl">
              Hi, {{ currentuser.first_name }}!
            </div>
            <div class="font-semibold text-4xl mt-4">
              Here is your onboarding journey!
            </div>
            <div
              :class="{
                'text-white': !data.hero_experience.availability.status,
              }"
              class="text-black w-1/2 mt-4"
            >
              Your onboarding journey is made fun with the use of AR, games,
              polls and lot more to make your start easy and fun with new
              experiences every week for you to get to know about the company.
            </div>
          </div>
          <div
            :class="{
              'text-white': !data.hero_experience.availability.status,
            }"
            class="text-black absolute bottom-10 left-10"
            v-if="data"
          >
            <div class="w-1/2">
              {{ data.hero_experience.experience.description }}
            </div>
            <div class="mt-4">
              <div
                class="mt-4 flex items-center gap-2"
                v-if="data.hero_experience.availability.status"
              >
                <div class="text-gray-900 text-lg font-semibold">
                  Click on play to get started
                </div>
                <div>
                  <img :src="require('@/assets/images/home/button-next.svg')" />
                </div>
              </div>
              <div class="font-semibold text-2xl text-white" v-else>
                {{ data.hero_experience.availability.text }}
              </div>
            </div>
          </div>
          <div
            class="absolute bottom-10 playButton"
            v-if="data.hero_experience.availability.status"
          >
            <img :src="require('@/assets/images/home/button-play.svg')" />
          </div>
        </router-link>
      </div>
      <div v-else class="endOfJourney w-full mt-4 text-center">
        <img :src="require('@/assets/images/home/end-of-journey.png')" />
      </div>
      <div
        id="remaining-exps"
        v-if="data"
        class="grid grid-cols-4 gap-4 justify-around mt-4 w-full"
      >
        <div
          class="relative"
          v-for="exp in data.remaining_experiences"
          :key="exp.experience.id"
        >
          <div
            class="w-full h-full absolute top-0 left-0 bg-black bg-opacity-50"
            v-if="!exp.availability.status"
          >
            <div class="px-6 py-4 font-semibold text-white text-lg">
              {{ exp.availability.text }}
            </div>
          </div>
          <router-link
            :to="
              exp.availability.status
                ? {
                    name: 'ExperienceDetailPage',
                    params: {
                      experience_link_id: exp.experience_link_id,
                    },
                  }
                : {}
            "
          >
            <img :src="exp.experience.icon.square" class="w-full h-auto" />
            <div
              :style="'background:' + exp.experience.base_color"
              class="mt-0.5 h-auto p-2"
            >
              {{ exp.experience.short_description }}
            </div>
          </router-link>
        </div>
      </div>
      <div
        class="mt-6 text-xl font-semibold flex gap-2 items-center border-t pt-4 border-gray-300"
        v-if="data.missed_experiences.length != 0"
      >
        Missed Experiences
        <img
          :src="require('@/assets/images/home/missed_exps.png')"
          class="h-6"
        />
      </div>
      <p class="text-sm pb-4" v-if="data.missed_experiences.length != 0">
        Here are all the experiences you missed, catch up on everything you
        missed to keep yourself updated about your workplace.
      </p>
      <div
        id="missed-experienced"
        v-if="data"
        class="grid grid-cols-4 gap-4 justify-around mt-2 w-full"
      >
        <div
          class="relative"
          v-for="exp in data.missed_experiences"
          :key="exp.experience.id"
        >
          <router-link
            :to="
              exp.availability.status
                ? {
                    name: 'ExperienceDetailPage',
                    params: {
                      experience_link_id: exp.experience_link_id,
                    },
                  }
                : {}
            "
          >
            <img :src="exp.experience.icon.square" class="w-full h-auto" />
            <div
              :style="'background:' + exp.experience.base_color"
              class="mt-0.5 h-auto p-2"
            >
              {{ exp.experience.short_description }}
            </div>
          </router-link>
        </div>
      </div>
      <div
        class="mt-6 text-xl font-semibold flex gap-2 items-center border-t pt-4 border-gray-300"
        v-if="data.already_experienced.length != 0"
      >
        Experience again
        <img :src="require('@/assets/images/home/exp_again.png')" class="h-6" />
      </div>
      <p class="text-sm pb-4" v-if="data.already_experienced.length != 0">
        Revisit experiences that are important to get a better understanding of
        your workplace.
      </p>
      <div
        id="already-experienced"
        v-if="data"
        class="grid grid-cols-4 gap-4 justify-around mt-2 w-full"
      >
        <div
          class="relative"
          v-for="exp in data.already_experienced"
          :key="exp.experience.id"
        >
          <router-link
            :to="
              exp.availability.status
                ? {
                    name: 'ExperienceDetailPage',
                    params: {
                      experience_link_id: exp.experience_link_id,
                    },
                  }
                : {}
            "
          >
            <img :src="exp.experience.icon.square" class="w-full h-auto" />
            <div
              :style="'background:' + exp.experience.base_color"
              class="mt-0.5 h-auto p-2"
            >
              {{ exp.experience.short_description }}
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div id="mobile-content" class="flex-grow block md:hidden" v-if="data">
      <div class="w-full">
        <ul class="flex shadow">
          <li
            :class="{
              'border-b border-purple-800': tab === 1,
            }"
            class="w-1/2"
          >
            <button
              @click="activeTabOne"
              :class="{
                'text-purple-800 font-semibold': tab === 1,
              }"
              class="text-gray-400 inline-block px-4 py-2.5 focus:outline-none text-lg"
            >
              New and upcoming
            </button>
          </li>
          <li
            :class="{
              'border-b border-purple-800': tab === 2,
            }"
            class="w-1/2"
          >
            <button
              @click="activeTabTwo"
              :class="{
                'text-purple-800 font-semibold': tab === 2,
              }"
              class="text-gray-400 inline-block px-4 py-2.5 focus:outline-none text-lg"
            >
              Experience again
            </button>
          </li>
        </ul>
      </div>
      <div class="mx-4">
        <div v-show="tab === 1">
          <router-link
            :to="
              data.hero_experience.availability.status
                ? {
                    name: 'ExperienceDetailPage',
                    params: {
                      experience_link_id:
                        data.hero_experience.experience_link_id,
                    },
                  }
                : {}
            "
            v-if="data.hero_experience"
          >
            <div id="hero-card" class="relative">
              <div class="text-black" v-if="currentuser">
                <div class="font-semibold text-3xl mt-4 text-nColorMustard">
                  Hi, {{ currentuser.first_name }}!
                </div>
                <div class="font-semibold text-xl mt-2">
                  Here is your onboarding journey!
                </div>
                <div class="text-black text-sm mt-4">
                  Your onboarding journey is made fun with the use of AR, games,
                  polls and lot more to make your start easy and fun with new
                  experiences every week for you to get to know about the
                  company.
                </div>
              </div>
              <div class="relative">
                <div
                  class="w-full h-full absolute top-0 left-0 bg-black bg-opacity-50"
                  v-if="!data.hero_experience.availability.status"
                ></div>
                <img
                  :src="data.hero_experience.experience.icon.square"
                  class="mt-4 w-full"
                />
                <div
                  :style="
                    'background:' + data.hero_experience.experience.base_color
                  "
                  class="mt-0.5 h-auto p-2"
                >
                  <div
                    class="font-semibold text-white"
                    v-if="!data.hero_experience.availability.status"
                  >
                    {{ data.hero_experience.availability.text }}
                  </div>
                  {{ data.hero_experience.experience.short_description }}
                </div>
              </div>
            </div>
          </router-link>
          <div v-else class="w-full endOfJourney mt-4 text-center">
            <img
              :src="require('@/assets/images/home/end-of-journey-mobile.png')"
            />
          </div>
          <div
            id="remaining-exps"
            v-if="data.remaining_experiences"
            class="grid grid-cols-1 gap-4 justify-around mt-4 w-full"
          >
            <div
              class="relative"
              v-for="exp in data.remaining_experiences"
              :key="exp.experience.id"
            >
              <div
                class="w-full h-full absolute top-0 left-0 bg-black bg-opacity-50"
                v-if="!exp.availability.status"
              ></div>
              <router-link
                :to="
                  exp.availability.status
                    ? {
                        name: 'ExperienceDetailPage',
                        params: {
                          experience_link_id: exp.experience_link_id,
                        },
                      }
                    : {}
                "
              >
                <img :src="exp.experience.icon.rectangle" />
                <div
                  :style="'background:' + exp.experience.base_color"
                  class="mt-0.5 h-auto p-2"
                >
                  <div
                    class="font-semibold text-white"
                    v-if="!exp.availability.status"
                  >
                    {{ exp.availability.text }}
                  </div>
                  {{ exp.experience.short_description }}
                </div>
              </router-link>
            </div>
          </div>
          <div
            class="mt-6 text-xl font-semibold flex gap-2 items-center border-t pt-4 border-gray-300"
            v-if="data.missed_experiences.length != 0"
          >
            <p>Missed Experiences</p>
            <img
              :src="require('@/assets/images/home/missed_exps.png')"
              class="h-6"
            />
          </div>
          <p class="text-sm pb-4">
            Here are all the experiences you missed, catch up on everything you
            missed to keep yourself updated about your workplace.
          </p>
          <div
            id="missed-experienced"
            v-if="data"
            class="grid grid-cols-2 gap-4 justify-around mt-2 w-full"
          >
            <div
              class="relative"
              v-for="exp in data.missed_experiences"
              :key="exp.experience.id"
            >
              <router-link
                :to="
                  exp.availability.status
                    ? {
                        name: 'ExperienceDetailPage',
                        params: {
                          experience_link_id: exp.experience_link_id,
                        },
                      }
                    : {}
                "
              >
                <img
                  :src="exp.experience.icon.rectangle"
                  class="w-full h-auto"
                />
                <div
                  :style="'background:' + exp.experience.base_color"
                  class="mt-0.5 h-14 p-2 text-sm"
                >
                  {{ exp.experience.short_description }}
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div v-show="tab === 2">
          <div
            id="already-experienced"
            v-if="data.already_experienced"
            class="grid grid-cols-2 gap-4 justify-around mt-2 w-full"
          >
            <div
              class="relative"
              v-for="exp in data.already_experienced"
              :key="exp.experience.id"
            >
              <router-link
                :to="
                  exp.availability.status
                    ? {
                        name: 'ExperienceDetailPage',
                        params: {
                          experience_link_id: exp.experience_link_id,
                        },
                      }
                    : {}
                "
              >
                <img :src="exp.experience.icon.rectangle" />
                <div
                  :style="'background:' + exp.experience.base_color"
                  class="mt-0.5 h-14 p-2 text-sm"
                >
                  {{ exp.experience.short_description }}
                </div>
              </router-link>
            </div>
          </div>
          <div v-else class="py-4">There are no experiences available!</div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Navbar from "@/components/Common/Navbar.vue";
import Footer from "@/components/Common/Footer.vue";
import slugsConfig from "@/common/slugs";

export default {
  name: "Home",
  components: { Navbar, Footer },
  data() {
    return {
      dataFetched: false,
      data: null,
      currentuser: null,
      slugsConfig,
      tab: 1,
    };
  },
  created() {
    const vue = this;
    ApiService.get(apiResource.homePage.getDetails)
      .then(({ data }) => {
        vue.dataFetched = true;
        vue.data = data.data;
        vue.currentuser = data.data.user;
      })
      .catch(() => {});
  },
  mounted() {},
  methods: {
    isMobile() {
      if (screen.width <= 768) {
        return true;
      } else {
        return false;
      }
    },
    activeTabOne() {
      this.tab = 1;
    },
    activeTabTwo() {
      this.tab = 2;
    },
  },
};
</script>

<style scoped>
.playButton {
  right: 17.4rem;
}
.endOfJourney {
  background: #231f20;
}
</style>
